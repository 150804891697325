import { Controller } from "stimulus";

export default class extends Controller {
  remove() {
    document.getElementById("rgpd-modal").classList.add("hidden");
    Rails.ajax({
      url: "/remove-rgpd-modal",
      type: "POST",
    });
  }
}
