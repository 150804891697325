import { Controller } from "stimulus";

export default class extends Controller {
  kind() {
    const element = this.targets.find("kind");
    this.selectValidation(element);
  }

  property() {
    const element = this.targets.find("property");
    this.selectValidation(element);
  }

  title() {
    const element = this.targets.find("title");
    this.selectValidation(element);
  }

  practice() {
    const element = this.targets.find("practice");
    this.selectValidation(element);
  }

  price() {
    const element = this.targets.find("price");
    this.regexMatchValidation(/^[0-9]\d*$/, element, true);
  }

  postalCode() {
    const element = this.targets.find("postal-code");
    this.regexMatchValidation(/^([0-9]{4}|[0-9]{5})$/, element, true);
  }

  phoneField() {
    const element = this.targets.find("phone");
    this.regexMatchValidation(
      /^(?:(?:\+|00)33|0)\s*[6-7](?:[\s.-]*\d{2}){4}$/,
      element,
      true
    );
  }

  emailField() {
    const element = this.targets.find("email");
    this.regexMatchValidation(
      /^[\w+\-.]+@[a-z\d\-]+(\.[a-z\d\-]+)*\.[a-z]+$/,
      element,
      true
    );
  }

  model() {
    const element = this.targets.find("model");
    this.textField(element);
  }

  label() {
    const element = this.targets.find("label");
    this.textField(element);
  }

  city() {
    const element = this.targets.find("city");
    this.textField(element);
  }

  address() {
    const element = this.targets.find("address");
    this.regexMatchValidation(
      /^([a-zA-Zàéèâêîôç0-9\s]|[a-zàéèâêîôçA-Z0-9\s][\. \'])+$/,
      element,
      false
    );
  }

  lastName() {
    const element = this.targets.find("last-name");
    this.textField(element);
  }

  firstName() {
    const element = this.targets.find("first-name");
    this.textField(element);
  }

  selectValidation(element) {
    if (element.value !== "") {
      this.addValidClass(element);
    } else {
      this.addInvalidClass(element);
    }
  }

  textField(element) {
    this.regexMatchValidation(
      /^([a-zA-Zàéèâêîôç0-9\s]|[a-zàéèâêîôçA-Z0-9\s][\. \'])+$/,
      element,
      false
    );
  }

  regexMatchValidation(regex, element, trim) {
    if (trim) {
      this.trimField(element);
    }
    regex.test(element.value)
      ? this.addValidClass(element)
      : this.addInvalidClass(element);
  }

  trimField(element) {
    element.value = element.value.trim();
  }

  addValidClass(element) {
    element.classList.remove("is-invalid");
    element.classList.add("is-valid");
  }

  addInvalidClass(element) {
    element.classList.add("is-invalid");
    element.classList.remove("is-valid");
  }
}
